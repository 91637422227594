<template>
  <div>
    <room-preparation-meeting
      :is-bbb="true"
      :url="urlToJoin"
      :waiting-state="isWaittingTeacher"
      :room="room"
    />
  </div>
</template>
<script>
import { RoomProvider } from '@copernicsw/community-common';
import RoomPreparationMeeting from '@/views/apps/rooms/components/room-details/RoomPreparationMeeting.vue';
import Axios from "axios";
const UNINITIALIZED = Symbol("BBB view state uninitialized");
const GETTING_MEETING_UUID = Symbol("BBB view state getting uuid");
const CHECKING_IF_JOINABLE = Symbol("BBB view state check if joinable");
const JOINING = Symbol("BBB view state joining");
const JOINED = Symbol("BBB view state joined");
const FAILED = Symbol("BBB view state failed");

export default {
  name: 'BigBlueButton',
  components: { RoomPreparationMeeting },
  props: {
    room: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      state: UNINITIALIZED,
      meetingUUID: null,
      loading: false,
      api: null,
      bbbAxios: Axios.create({
        baseURL: 'https://classroom.nectios.com/bebop/api/v1/',
        headers: {},
      }),
      isWaittingTeacher: false,
      isNotHavingTemplate: false,
      urlToJoin: null,
      messageHandle: null,
    };
  },
  computed: {
    userID() {
      return this.$store.getters.loggedUser.key;
    },
    userFullName() {
      return `${this.$store.getters.loggedMember.name} ${this.$store.getters.loggedMember.surname}`;
    },
    workspaceID() {
      return this.room.app === RoomProvider.bbbUsingCommunityKey ? this.$store.getters.currentCollective.key : this.room.key;
    },
    authorizationToken() {
      return this.axios.defaults.headers.common["Authorization"];
    },
    isFailed() {
      return this.state === FAILED;
    },
  },
  watch: {
    async state(newState) {
      if (newState === GETTING_MEETING_UUID) {
        await this.obtainMeetingUUID();
      } else if (newState === CHECKING_IF_JOINABLE) {
        await this.checkIfJoinable();
      } else if (newState === JOINING) {
        await this.attemptJoin();
      }
    },
  },
  methods: {
    async obtainMeetingUUID() {
      const resp = await this.bbbAxios.post('get_meeting_uuid_for_collective', {
        collectiveID: this.workspaceID,
      });

      if (resp.data.success) {
        this.meetingUUID = resp.data.meetingUUID;
        this.state = CHECKING_IF_JOINABLE;
      } else {
        this.state = FAILED;
      }
    },
    async checkIfJoinable() {
      const resp = await this.bbbAxios.post('is_joinable', {
        meetingUUID: this.meetingUUID,
        userID: this.userID,
        isStaff: this.$store.getters.currentCollective.isStaff,
      });

      if (resp.data.success && resp.data.isJoinable) {
        this.isWaittingTeacher = false;
        this.state = JOINING;
      } else if (resp.data.isJoinable === false) {
        if (resp.data.unableToJoinReason === 'NoTeacherPresent') {
          this.isWaittingTeacher = true;
          setTimeout(this.checkIfJoinable, 5000);
        } else {
          this.state = FAILED;
        }
      }
    },
    async attemptJoin() {
      const resp = await this.bbbAxios.post("join", {
        meetingUUID: this.meetingUUID,
        userID: this.userID,
        fullName: this.userFullName,
        isStaff: this.$store.getters.currentCollective.isStaff,
        avatarURL: this.avatarURL,
      });

      if (resp.data.success) {
        this.urlToJoin = resp.data.url;
      } else {
        this.state = FAILED;
      }
    },
    handleMessage(ev) {
      if (
        ev.origin === 'https://classroom.nectios.com' &&
        ev.data?.type === "ping"
      ) {
        ev.source.postMessage(
          {
            type: 'config',
            accessToken: this.authorizationToken,
            communitySlug: this.workspaceID,
            apiHost: 'classroom.nectios.com',
            trackPath: `workspace/${this.workspaceID}/classroom`,
          },
          'https://classroom.nectios.com',
        );
      }
    },
    handleJoinClick() {
      window.open(this.urlToJoin, 'bbb');
      this.state = JOINED;
    },
  },
  mounted() {
    this.state = GETTING_MEETING_UUID;
    this.messageHandle = window.addEventListener("message", this.handleMessage);
  },
  umounted() {
    window.removeEventListener("message", this.messageHandle);
  },
};
</script>
<style lang="sass" scoped></style>
