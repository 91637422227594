<template>
  <b-row class="room-row flex-column flex-md-row py-2 px-3">
    <b-col cols="12" md="4" class="py-50 px-0">
      <safe-img
        :src="backgroundImage"
        :placeholder="placeholder"
        class="room-img"
      />
    </b-col>
    <feather-icon
      v-if="canDelete"
      icon="Trash2Icon"
      size="18"
      class="text-primary delete-icon"
      role="button"
      @click="deleteItem(room.key)"
    />
    <feather-icon
      v-if="canEdit"
      icon="Edit2Icon"
      size="18"
      class="text-primary edit-icon mr-50"
      role="button"
      @click="openEditModal"
    />
    <b-col class="details-col pr-0 pl-0 pl-md-2 pl-lg-3 py-50">
      <h1>{{ room.name }}</h1>

      <event-period
        :start-date="room.openingTime"
        :end-date="room.closingTime"
        :all-day="room.allDay"
        class="text-primary font-weight-bolder mb-0 mt-1"
      />
      <hr />
      <content-with-anchors tag="p" :content="room.description" />
      <b-button
        v-if="canAccess"
        variant="primary"
        :href="null"
        target="_blank"
        @click="$emit('join-meeting')"
      >
        {{ $t("rooms.action.join") }}
      </b-button>
      <!-- User can not access -->
      <b-alert v-if="!canAccess" show class="p-1">
        {{ $t("rooms.message.no-access") }}
      </b-alert>
    </b-col>
    <edit-room-modal
      v-if="isEditModalOpened"
      v-model="isEditModalOpened"
      :room="room"
      @edited="handleEdited"
    />
    <unshare-modal
      v-model="isUnshareModalVisible"
      model-type="room"
      @remove-item="handleRemoveItem"
    />
  </b-row>
</template>

<script>
import { BRow, BCol, BButton, BAlert } from "bootstrap-vue";
import { getImageResource } from "@/@core/utils/image-utils";
import SafeImg from "@core/components/safe-img/SafeImg.vue";
import ContentWithAnchors from "@core/components/string-utils/ContentWithAnchors.vue";
import EventPeriod from "@/views/apps/events/components/EventPeriod.vue";
import { RoomProvider } from "@copernicsw/community-common";
import RoomPlaceholder from "@/assets/images/placeholders/light/general-placeholder.jpeg";
import Service from "@/config/service-identifiers";
import FileTypes from "@/@core/constants/FileTypes";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import UnshareModal from "@/@core/components/modal/UnshareModal.vue";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import { checkPermissions } from "@/@core/utils/roles-utils";
import EditRoomModal from '@/views/apps/rooms/components/EditRoomModal.vue';

export default {
  name: "RoomWelcomeMeeting",
  components: {
    BRow,
    BCol,
    BButton,
    BAlert,
    SafeImg,
    ContentWithAnchors,
    EventPeriod,
    EditRoomModal,
    UnshareModal,
  },
  props: {
    room: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      userCanAccess: "",
      formInput: {},
      itemImage: null,
      imageSrc: null,
      selectedOption: "",
      selectedApp: "",
      selectedType: "",
      appRoom: [
        "Bluejeans",
        "Nectios Classroom",
        "Zoom.us",
        "Jisti",
      ],
      questionsToShow: [],
      isUnshareModalVisible: false,
      isEditModalOpened: false,
    };
  },
  computed: {
    typeEventsOptions() {
      return [
        this.$t("rooms.online"),
        this.$t("rooms.on-site"),
        this.$t("rooms.hybrid"),
      ];
    },
    typeOptions() {
      return ["Meeting", "Webinar"];
    },
    FileTypes() {
      return FileTypes;
    },
    canEdit() {
      return (
        this.collective.isStaff ||
        checkPermissions(
          "update",
          "room",
          this.loggedMemberRoles,
          this.collective
        )
      );
    },
    canDelete() {
      return (
        this.collective.isStaff ||
        checkPermissions(
          "delete",
          "room",
          this.loggedMemberRoles,
          this.collective
        )
      );
    },
    collective() {
      return this.$store.state.collective.collective;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    backgroundImage() {
      return getImageResource(this.room.backgroundURL);
    },
    placeholder() {
      return RoomPlaceholder;
    },
    isJitsiConferencing() {
      return this.room.app === RoomProvider.jitsi;
    },
    isBlueJeans() {
      return this.room.app === RoomProvider.bluejeans;
    },
    canAccess() {
      if (this.room.Meetings && this.room.Meetings.length > 0) {
        return this.room.Meetings.some(
          (item) => item.isAccepted || item.isSection || this.userCanAccess
        );
      }
      return this.userCanAccess;
    },
    communitySlug() {
      return this.$route.params.communityId;
    },
  },
  async created() {
    this.videocallUsersCanAccessNow();
    await this.getApp();
    this.formInput = { ...this.room };
    this.toModality();
    this.selectedApp = this.formInput.app;
    this.toApps();
    this.selectedType =
      this.formInput.type[0].toUpperCase() + this.formInput.type.slice(1);
  },
  methods: {
    async handleRemoveItem(response) {
      const temo = {
        tableName: "rooms",
        key: this.room.key,
      };
      if (response === true) {
        try {
          await this.$store.dispatch("deleteRooms", {
            item: temo,
            type: "rooms/delete",
          });
          this.$router.push({
            name: "rooms",
            params: { communityId: this.$route.params.communityId },
          });
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: this.$t("unshare.toast-messages.success", {
                item: this.$t("unshare.singular-items.room"),
              }),
              icon: "CheckIcon",
              variant: "success",
            },
          });
        } catch {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: this.$t("unshare.toast-messages.error", {
                item: this.$t("unshare.singular-items.room"),
              }),
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }
      }
    },
    deleteItem() {
      this.isUnshareModalVisible = true;
    },
    translate(string) {
      return translateTranslationTable(this.currentLocale, string);
    },
    selectQuestions() {
      this.questionsToShow = [];
      for (let row of this.appRoom) {
        this.questionsToShow.push({
          name: this.translate(row.name),
          key: row.key,
        });
      }
    },
    async getApp() {
      // await this.$store.dispatch('getItems', {
      //     itemType: 'addons/rooms/installed',
      //     customName :'appRoom'
      //   });
      const response = await this.$store.$service[Service.BackendClient].get(
        "addons/rooms/installed",
        {
          params: { communitySlug: this.communitySlug },
        }
      );
      this.appRoom[0] = "Conferencing";
      if (response.data) {
        for (let row of response.data) {
          this.appRoom.push(row.name.en);
        }
      }
      this.selectQuestions();
    },
    appss() {
      switch (this.selectedApp) {
        case "Conferencing":
          this.formInput.app = "jitsi";
          return;
        case "Zoom.us":
          this.formInput.app = "zoom";
          return;
        case "Nectios Classroom":
          this.formInput.app = "classroom";
          return;
        case "Bluejeans":
          this.formInput.app = "bluejeans";
          return;
        case "Jisti":
          this.formInput.app = "jitsi";
          return;
      }
    },
    toApps() {
      switch (this.selectedApp) {
        case 0:
          this.selectedApp = "Zoom.us";
          return;
        case 1:
          this.selectedApp = "Conferencing";
          return;
        case 2:
          this.selectedApp = "Nectios Classroom";
          return;
        case 3:
          this.selectedApp = "Bluejeans";
          return;
      }
    },
    toModality() {
      switch (this.formInput.modality) {
        case "physical":
          this.selectedOption = this.typeEventsOptions[1];
          return;
        case "hybrid":
          this.selectedOption = this.typeEventsOptions[2];
          return;
        case "online":
          this.selectedOption = this.typeEventsOptions[0];
          return;
      }
    },
    modality() {
      switch (this.selectedOption) {
        case this.typeEventsOptions[1]:
          this.formInput.modality = "physical";
          return;
        case this.typeEventsOptions[2]:
          this.formInput.modality = "hybrid";
          return;
        case this.typeEventsOptions[0]:
          this.formInput.modality = "online";
          return;
      }
    },
    type() {
      switch (this.selectedType) {
        case this.typeOptions[0]:
          this.formInput.type = "meeting";
          return;
        case this.typeOptions[1]:
          this.formInput.type = "webinar";
          return;
      }
    },
    openEdit() {
      this.$bvModal.show("modal-edit-room");
    },
    async videocallUsersCanAccessNow() {
      try {
        if(this.room.isEvent) {
          return true;
        }
        // TODO: use member store
        const response = await this.$store.$service[Service.BackendClient].get(
          "/videocallUsersCanAccessNow",
          {
            params: {
              roomKey: this.room.key,
            },
          }
        );
        this.userCanAccess = response.data.responseData.ok;
      } catch (error) {
        this.userCanAccess = false;
      }
    },
    handleEdited() {
      this.isEditModalOpened = false;
      this.$emit('edited');
    },
    openEditModal() {
      this.isEditModalOpened = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.edit-icon {
  position: absolute;
  top: -38px;
  right: 40px;
  cursor: pointer;
}
.delete-icon {
  position: absolute;
  top: -38px;
  right: 10px;
  cursor: pointer;
}
</style>