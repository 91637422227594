<template>
  <div id="bluejeans_holder" />
</template>
<script>
import { BJNEmbedSDK } from 'bluejeans-webrtc-embed-sdk';
import Service from '@/config/service-identifiers';

export default {
  name: 'BlueJeansMeeting',
  props: {
    room: {
      type: Object,
      required: true,
    },
  },
  created() {
    if (!this.room.identifier) {
      this.getBluejeansInfo();
    }
  },
  mounted() {
    BJNEmbedSDK.joinMeeting({
      meetingInfo: {
        meetingId: this.room.identifier,
        passcode: this.room.moderatorPasscode
          ? this.room.moderatorPasscode : this.room.password,
        name: this.$store.getters.loggedUser.name,
      },
      iFrameProps: {
        selectorId: '#bluejeans_holder',
      },
      uiProps: {
        customBackground: '#28ABB9',
        hideFooter: true,
        hideRatingScreen: true,
        hideCopyLink: true,
        hideOtherJoinOptions: true,
        hideRoomJoinOption: true,
        hideAppPitches: true,
        hideSignInButton: true,
      },
    });
  },
  methods: {
    async getBluejeansInfo() {
      const response = await this.$store.$service[Service.BackendClient].post('/bluejeanRoom', {
        roomKey: this.room.key,
      });
      this.room.identifier = response.data.attendeePin;
      this.room.moderatorPasscode = response.data.bluejeanID;
    },
  },
};
</script>
